<template>
    <div class="container-fluid">
        <div v-if="selectedShop.applicationId === '0'">
            <p>Please select a shop first</p>
        </div>
        <router-view
            v-else
            ref="routeview"
            v-bind="productBinding"
            @fetchProductContent="getCatalogProductDetails"
            @fetchCatalogProductVariant="getVariantDetails"
        ></router-view>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'CatalogProductView',
    props: {
        catalogProductId: {},
        productVariantId: {},
    },
    data () {
        return {
            breadcrumbElement: {
                name: 'catalog',
            },
            productContent: null,
            product: null,
            productVariant: null,
        }
    },
    computed: {
        productBinding () {
            return {
                productContent: this.productVariantId && this.productVariant && this.productVariant.productContent ? this.productVariant.productContent : this.productContent,
                product: this.product,
                productVariant: this.productVariant,
                productVariantId: this.productVariantId,
            }
        },
    },
    methods: {
        async getCatalogProductDetails () {
            this.$set(this, 'product', null)
            return this.get(CONFIG.API.ROUTES.CATALOG.GET.replace('{catalogProductId}', this.catalogProductId)).then(data => {
                this.$set(this, 'productContent', data.data.productContent || {})
                this.$set(this, 'product', data.data || {})
            })
        },
        async getVariantDetails () {
            if (this.isValidUUID(this.productVariantId)) {
                return this.get(CONFIG.API.ROUTES.CATALOG.VARIANTS.GET.replace('{productVariantId}', this.productVariantId)).then(data => {
                    this.$set(this, 'productVariant', data.data || {})
                })
            }
        },
        isValidUUID (uuid) {
            return /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test((uuid || ''))
        },
    },
    created () {
        this.$watch(function () {
            return [this.productVariantId, this.selectedShop]
        }, async () => {
            if (!this.selectedShop || this.selectedShop.applicationId === '0') {
                return
            }
            this.$set(this, 'productVariant', null)

            return this.getCatalogProductDetails().then(() => {
                if (!this.isValidUUID(this.productVariantId)) {
                    this.$router.replace({
                        name: 'CatalogProductView',
                    })
                    return
                }
                return this.getVariantDetails()
            })
        }, {
            immediate: true,
        })
    },
}
</script>
